import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`*`}{`Attention Louisville East 6:00am class attendees.  Class will be
cancelled tomorrow because there is still no water at East.  Please
attend The Ville’s 6:00am class instead.  We are planning on having both
the 10:30 and 4:00 class at East.  Thanks and sorry for the
inconvenience.  `}</strong></p>
    <p><em parentName="p">{`Metcon Week starts today!`}</em></p>
    <p>{`500M Row,`}</p>
    <p>{`2 rounds of:`}</p>
    <p>{`25-Power Snatch (75/55)`}</p>
    <p>{`15-Burpees Over Bar`}</p>
    <p>{`then,`}</p>
    <p>{`400M Row,`}</p>
    <p>{`2 rounds of:`}</p>
    <p>{`20-OHS`}</p>
    <p>{`10-GHD Situps`}</p>
    <p>{`then,`}</p>
    <p>{`300M Row,`}</p>
    <p>{`2 rounds of:`}</p>
    <p>{`15-Full Snatch`}</p>
    <p>{`5-Bar Muscle Ups`}</p>
    <p>{`For total time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will have our free Labor Day workout at 10:30 at CrossFit The
Ville this coming Monday so invite a friend!  All other classes and open
gym is cancelled for the day.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`True Grit 5, our annual 4 person team competition, will take place
this year on November 4th!  Get your teams together now.  More info to
come.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      